import { FormattedNumber, FormattedMessage as T } from 'react-intl';
import { useGlobalConfig } from '../../hooks/configContext';
import m from './messages';
import { PerUnitType } from './types';
import useCountAnimation from './useCountAnimation';

type Props = {
  perUnit?: PerUnitType | null | undefined;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  value: number;
  animateChanges?: boolean;
  duration?: number;
  currency?: string;
};

const getPerUnit = (perUnit?: PerUnitType | null) => {
  if (!perUnit) {
    return null;
  }
  return {
    kWh: m.kWh,
    month: m.month,
    year: m.year,
  }[perUnit];
};

const FormattedCurrency = ({
  value,
  perUnit,
  animateChanges = false,
  maximumFractionDigits,
  minimumFractionDigits,
  duration = 200,
  currency,
}: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  const isWholeNumber = value === Math.round(value);
  const defaultFractionUnits = isWholeNumber ? 0 : 2;
  const [animatedValue] = useCountAnimation(value, duration);

  const per = getPerUnit(perUnit);

  return (
    <span style={{ whiteSpace: 'nowrap' }} className="notranslate">
      <FormattedNumber
        value={animateChanges ? animatedValue : value}
        minimumFractionDigits={
          minimumFractionDigits !== undefined
            ? minimumFractionDigits
            : defaultFractionUnits
        }
        maximumFractionDigits={
          maximumFractionDigits !== undefined
            ? maximumFractionDigits
            : defaultFractionUnits
        }
        style="currency"
        currency={currency || BU_CONFIG.currency}
      />
      {per && (
        <>
          /
          <T {...per} />
        </>
      )}
    </span>
  );
};

export default FormattedCurrency;
