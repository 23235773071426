import { TabPanel } from '@headlessui/react';
import FormattedCurrency from '@otovo/shared/components/Currency/FormattedCurrency';
import m from '@otovo/shared/components/PurchaseModelComparison/messages';
import cx from 'classnames';
import { FormattedMessage as T } from 'react-intl';
import SellingPointsOwnership from './SellingPointsOwnership';

type Props = {
  startingPriceOwnership: number;
};

const Ownership = ({ startingPriceOwnership }: Props) => {
  return (
    <TabPanel className="grid grid-cols-1 gap-10 md:grid-cols-2">
      <SellingPointsOwnership />
      <div className="flex flex-col space-y-14 text-grey-90">
        <div className="w-full">
          <p className="mb-3 text-base text-grey-60">
            <T {...m.ownershipIntroduction} />
          </p>
        </div>
        <div className="w-full">
          <p className="mb-10 tracking-tight text-grey-60 sm:text-2xl">
            <T {...m.fromPrice} />{' '}
            <span className="text-[55px] font-semibold text-grey-90">
              <FormattedCurrency value={startingPriceOwnership} />
            </span>
          </p>
          <button
            type="button"
            className={cx(
              'w-full rounded-full bg-peach-50 px-6 py-4 text-lg font-medium text-grey-90',
              'hover:bg-peach-60',
              'focus:outline-none focus:ring-2 focus:ring-peach-80',
              'active:bg-peach-60 active:ring-2 active:ring-inset active:ring-peach-80',
            )}
            onClick={() => {
              window.scroll({
                top: 0,
                behavior: 'smooth',
              });
            }}
          >
            <T {...m.scrollToTopButton} />
          </button>
        </div>
      </div>
    </TabPanel>
  );
};

export default Ownership;
